export const Auth_constants = {
  sf_token_url: 'https://pljpxyka7dvcxjsjn7usw7qlva0vpgpm.lambda-url.ap-southeast-1.on.aws'
};

export const endpoints = {
  designs: 'tbtdesignservice',
  templates: 'tbttemplateservice',
  query: 'data/v58.0/query/',
  heqportal:'resources?type=Browse%20All',
  savedItems:'customized-templates',
  getSalesforceToken: 'get-sf-user-token',
  previewFile: 'get-file-s3',
  listPresignedUrl: 'list-presigned-urls',
  uploadFiles: 'upload-files',
  copyFiles: 'copy-files',
  listFiles: 'list-files',
  apiGatewayDev: 'yd0677gs8i-vpce-0ef70c6611c9b5190',
  apiGatewayUat: '2sao0yz3jk-vpce-0e6a4e56e91b1fcdc',
  apiGatewayProd: '3h96r1497k-vpce-0305fe0b5ff2a7df3',
}

const local = {
  cognitoRedirectUrl: 'http://localhost:4200/verify-token',
  cognitoClientId: '73r0s5ml9pqlv1svp2uq5trpls',
  cognitoClientSecret: '1e9lmqculboqfqrim3utpoqj70no96o2b6s21quso6uciug6cv05',
  cognitoDomainUrl: 'https://tbt-dev.auth.ap-southeast-1.amazoncognito.com',
  cognitoIdentityProvider: 'pingFedDev',
  proxyApiKey: 'yfPEnEbmAQzNPAoQdMJUrIRieVTuDDhw',
  proxyApi: 'https://api-test.merck.com/healtheq-template-builder/v1/',
  awsRegion: 'ap-southeast-1',
  apiGatewayResourceId: 'yd0677gs8i',
  apiGatewayVPCEndpointId: 'vpce-0ef70c6611c9b5190',
  tbtDomainUrl: 'http://localhost:4200',
  heqPortaledirectionUrl: 'https://msdlogin--hhusd1.sandbox.my.site.com/healtheq/',
  dataDomainScript: '019256c0-848c-79be-8b63-2812ab84ed6f-test'
};

const dev = {
  cognitoRedirectUrl: 'https://tbt-dev.healtheq.com/verify-token',
  cognitoClientId: '73r0s5ml9pqlv1svp2uq5trpls',
  cognitoClientSecret: '1e9lmqculboqfqrim3utpoqj70no96o2b6s21quso6uciug6cv05',
  cognitoDomainUrl: 'https://tbt-dev.auth.ap-southeast-1.amazoncognito.com',
  cognitoIdentityProvider: 'pingFedDev',
  proxyApiKey: 'yfPEnEbmAQzNPAoQdMJUrIRieVTuDDhw',
  proxyApi: 'https://api-test.merck.com/healtheq-template-builder/v1/',
  awsRegion: 'ap-southeast-1',
  apiGatewayResourceId: 'yd0677gs8i',
  apiGatewayVPCEndpointId: 'vpce-0ef70c6611c9b5190',
  tbtDomainUrl: 'https://tbt-dev.healtheq.com',
  heqPortaledirectionUrl: 'https://msdlogin--hhusd1.sandbox.my.site.com/healtheq/',
  dataDomainScript: '019256c0-848c-79be-8b63-2812ab84ed6f-test'
};

const uat = {
  cognitoRedirectUrl: 'https://tbt-uat.healtheq.com/verify-token',
  cognitoClientId: '6hnc4bi3ffal7kjrh58hvi30sv',
  cognitoClientSecret: '1tue5vr96q8apod6l90kg1388jjtriuq7l6a2rft0gf0r8m7ccuo',
  cognitoDomainUrl: 'https://heq-tbt-uat.auth.us-east-1.amazoncognito.com',
  cognitoIdentityProvider: 'pingfed-uat',
  proxyApiKey: 'yfPEnEbmAQzNPAoQdMJUrIRieVTuDDhw',
  proxyApi: 'https://api-qa.merck.com/healtheq-template-builder/v1/',
  awsRegion: 'us-east-1',
  apiGatewayResourceId: '2sao0yz3jk',
  apiGatewayVPCEndpointId: 'vpce-0e6a4e56e91b1fcdc',
  tbtDomainUrl: 'https://tbt-uat.healtheq.com',
  heqPortaledirectionUrl: 'https://uat.healtheq.com/',
  dataDomainScript: '019256c0-848c-79be-8b63-2812ab84ed6f-test'
};

const prod = {
  cognitoRedirectUrl: 'https://tbt.healtheq.com/verify-token',
  cognitoClientId: '3gn9lqfn049efpttiiv8r2e1q0',
  cognitoClientSecret: 'chqilod84ldf3p1aigirqv4cmm70kq0minldq3o2phm0m0bedg2',
  cognitoDomainUrl: 'https://heq-tbt.auth.us-east-1.amazoncognito.com',
  cognitoIdentityProvider: 'pingfed-prod',
  proxyApiKey: 'w7wg9EgM119VFHgDK3VDCgGDY3AwJayS',
  proxyApi: 'https://api.merck.com/healtheq-template-builder/v1/',
  awsRegion: 'us-east-1',
  apiGatewayResourceId: '3h96r1497k',
  apiGatewayVPCEndpointId: 'vpce-0305fe0b5ff2a7df3',
  tbtDomainUrl: 'https://tbt.healtheq.com',
  heqPortaledirectionUrl: 'https://www.healtheq.com/',
  dataDomainScript: '0193b0c1-c964-7a27-a996-65733fb70419'
};

export const idp = {
  local,
  dev,
  prod,
  uat,
  scope: 'email+openid+phone+profile',
};

export const links = {
  privacyPolicy: 'https://www.msdprivacy.com/us/en/',
  termsOfUse: 'https://www.merck.com/terms-of-use/',
  accessibility: 'https://www.msdaccessibility.com/',
  cookieScript: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
  imgLink: 'https://www.levelaccess.com/a/merck?utm_source=merck-homepage&utm_medium=icon-large&utm_term=eachannel-page&utm_content=header&utm_campaign=merck',
  // dataDomainScript: '019256c0-848c-79be-8b63-2812ab84ed6f-test'
}
export interface AuthPayload {
  grant_type: string;
  client_id: string;
  client_secret: string;
  username: string;
  password: string;
}


export interface AuthResponse {
  access_token: string;
  id: string;
  instance_url: string;
  issued_at: string;
  signature: string;
  token_type: string;
}

export interface TemplateServiceRequest {
  method_code: string;
  user_id: string;
  role: string;
}


export interface TemplateDesign {
  template_id: string;
  template_name: string;
  template_author?: string | null;
  template_approver?: string | null;
  template_submitted_date?: string | null;
  template_approved_date?: string | null;
  template_language?: string | null;
  thumbnail?: string | null;
  status?: string | null;
  job_code: string;
  design_name?: string | null;
  lastmodified_date?: string | null;
  module_configuration?: any;  
  canvas_configuration: CanvasElement[];
}

export interface CanvasElement {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  page_number: number;
  element_url: string;
  placeholder:string;
  element_name: string;
  type: string;
  customizable: boolean;
}

export interface TemplateCreationResponse {
  design_name: string | undefined;
  job_code: string;
  template_id: string;
  template_name: string;
  template_status: string;
  user_id: string;
}

export interface DesignMetadataResponse {
  moduleAssetMapping: ModuleAssetMapping[];
  staticFiles: StaticFile[];
  mappingGuideLinesFile: string;
  finishedFile: string;
}

export interface ModuleAssetMapping {
  module_name: string;
  assets: Asset[];
}

export interface Asset {
  assetName: string;
  assetId: string;
}

export interface StaticFile {
  fileType: string;
  fileImgThumbnailLink: string;
  fileImgLink: string;
  fileName: string;
  fileId: string;
}

