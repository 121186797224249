import { Component, inject, Inject, LOCALE_ID, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle,
} from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { DesignsService } from '../../../../services/designs.service';
import { Design } from '../../../../model/design';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgTemplateOutlet } from '@angular/common';
import { MatOption, MatSelect } from '@angular/material/select';
import { SpinnerService } from '@app/shared/services/spinner.service';
import { loadTargetTreeSuccess } from '@app/shared/store/actions/standardise-design.actions';
import { Store } from '@ngrx/store';
import { printSpecs } from '../../../../../../../assets/static-data/print-config';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { HEQDateAdapter } from '@app/shared/adapters/heq-date-adapter.adapter';

@Component({
  selector: 'app-design-properties',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatDialogContent,
    MatDialogActions,
    MatFormField,
    MatDialogTitle,
    MatButton,
    MatInputModule,
    MatCheckbox,
    MatLabel,
    MatSelect,
    MatOption,
    MatDatepickerModule,
    NgTemplateOutlet,
  ],
  templateUrl: './design-properties.component.html',
  styleUrl: './design-properties.component.scss',
})
export class DesignPropertiesComponent implements OnInit {

  private readonly designService = inject(DesignsService);
  private readonly fb = inject(FormBuilder);
  private readonly store = inject(Store);

  propertiesForm!: FormGroup;
  sectionForm!: FormGroup;

  formType: 'design' | 'section' = 'design';
  design!: Design;

  designTypes = ['templated', 'picklist', 'picklist_with_templated'];
  documentTypes = ['a4', 'a3', 'a5', 'tabloid', 'letter', 'custom'];
  printCodeTypes = printSpecs;
  orientations = ['portrait', 'landscape'];
  languages = ['English', 'Spanish', 'Chinese'];
  readonly locale = inject(LOCALE_ID);

  constructor(
    public dialogRef: MatDialogRef<DesignPropertiesComponent>,
    private spinnerService: SpinnerService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      jobCode: string;
      selectedNode: any;
      formType: 'design' | 'section';
      designData:any;
    }
  ) {}

  ngOnInit(): void {
    console.log('locale: ', this.locale);
    
    console.log(this.data);
    // if (this.data.jobCode) {
    //   this.getDesignData();
    // }
    this.formType = this.data.formType;
    this.design = this.data.designData;
    this.renderForm(this.data.selectedNode);
  }

  private getDesignData() {
    this.spinnerService.show();
    this.designService
      .getDesign(this.data.jobCode)
      .subscribe((design: Design) => {
        this.spinnerService.hide();
        console.log('design: ', design);
        this.design = design;
        this.renderForm(this.data.selectedNode);
      });
  }

  private renderForm(existingSection?: any) {
    if (this.data.formType === 'design') {
      let existingDesign: {};
      try {
        existingDesign = this.design.canvas_properties
          ? JSON.parse(this.design.canvas_properties)
          : {};
      } catch (error) {
        existingDesign = {};
      }
      this.renderDesignPropertiesForm(existingDesign);
    } else if (this.data.formType === 'section') {
      if (this.design.static_files?.length) {
        this.design.static_files.forEach((file: any) => {
          if (
            file.section_id === existingSection?.id ||
            file.id === existingSection?.id
          ) {
            existingSection = file;
          }
        });
      }
      existingSection = existingSection.properties
        ? JSON.parse(existingSection.properties)
        : {};
      this.renderStaticFilePropertiesForm(existingSection);
    }
  }

  renderDesignPropertiesForm(existingSection?: any) {

    const designDate = this.design.expiry_date ?? existingSection?.expirationDate ?? '';

    this.propertiesForm = this.fb.group({
      designName: [
        existingSection?.designName || this.design.design_name,
        Validators.required,
      ],
      designType: [existingSection?.designType || '', Validators.required],
      documentType: [existingSection?.documentType || '', Validators.required],
      expirationDate: [designDate, Validators.required],
      printCode: [existingSection?.printCode || '', Validators.required],
      documentWidth: [
        existingSection?.documentWidth || '',
        Validators.required,
      ],
      documentHeight: [
        existingSection?.documentHeight || '',
        Validators.required,
      ],
      documentOrientation: [
        existingSection?.documentOrientation || '',
        Validators.required,
      ],
      language: [existingSection?.language || '', Validators.required],
      pageCount: [existingSection?.pageCount || '', Validators.required],
    });
  }

  renderStaticFilePropertiesForm(existingSection?: any) {
    this.sectionForm = this.fb.group({
      file_type: [existingSection?.file_type || '', Validators.required],
      page_number: [existingSection?.page_number || 0, Validators.required],
      is_customizable: [existingSection?.is_customizable || false],
      imageGroup: this.fb.group({
        x: [existingSection?.imageGroup?.x || 0, Validators.required],
        y: [existingSection?.imageGroup?.y || 0, Validators.required],
        width: [existingSection?.imageGroup?.width || 0, Validators.required],
        height: [existingSection?.imageGroup?.height || 0, Validators.required],
      }),
      zoneGroup: this.fb.group({
        x: [existingSection?.zoneGroup?.x || 0],
        y: [existingSection?.zoneGroup?.y || 0],
        width: [existingSection?.zoneGroup?.width || 0],
        height: [existingSection?.zoneGroup?.height || 0],
      }),
    });

    this.checkIsCustomizableFile();
  }

  checkIsCustomizableFile() {
    const isCustomizableControl = this.sectionForm.get('is_customizable');
    if (isCustomizableControl) {
      isCustomizableControl.valueChanges.subscribe(isCustomizable => {
        const zoneGroup = this.sectionForm.get('zoneGroup');
        if (zoneGroup) {
          if (isCustomizable) {
            zoneGroup.setValidators([Validators.required]);
            Object.keys((zoneGroup as FormGroup).controls).forEach(key => {
              const control = zoneGroup.get(key);
              if (control) {
                control.setValidators([Validators.required]);
              }
            });
          } else {
            zoneGroup.clearValidators();
            Object.keys((zoneGroup as FormGroup).controls).forEach(key => {
              const control = zoneGroup.get(key);
              if (control) {
                control.clearValidators();
              }
            });
            zoneGroup.markAsPristine();
          }
          zoneGroup.updateValueAndValidity();
        }
      });
    }
  }

  updateProperties() {
    // [disabled]="formType === 'design' ? !propertiesForm.valid : !sectionForm.valid"
    const myForm =
      this.formType === 'design' ? this.propertiesForm : this.sectionForm;

    if (this.sectionForm) {
      this.checkIsCustomizableFile();
    }

    let formValid = myForm.valid;
    const isCustomizableControl = this.sectionForm?.get('is_customizable');

    if ( this.formType !== 'design'&& !isCustomizableControl?.value && this.sectionForm.get('imageGroup')?.valid){
        formValid = true;
    }

    if (formValid) {
      console.log('Form submitted', myForm.value);
      const designCopy = JSON.parse(JSON.stringify(this.design));
      if (this.formType !== 'design') {
        designCopy.static_files.forEach((file: any) => {
          if (this.data.selectedNode['id'] === file.id) {
            file.properties = JSON.stringify(myForm.value);
          }
        });
      } else {
        if (!designCopy?.language) {
          designCopy.language = myForm.value.language;
        }
        if (!designCopy?.template_type) {
          designCopy.template_type = myForm.value.designType || 'templated';
        }
        if (!designCopy.expiry_date) {
          const selectedDate: Date = myForm.value.expirationDate;

          const year = selectedDate.getFullYear();
          const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
          const day = selectedDate.getDate().toString().padStart(2, '0');
          const formattedDate = `${year}-${month}-${day}`; // Return YYYY-MM-DD

          // const formattedDate = selectedDate ? selectedDate.toISOString().split('T')[0] : null;
          designCopy.expiry_date = formattedDate;
          myForm.value.expirationDate = formattedDate;
        }
        designCopy.canvas_properties = JSON.stringify(myForm.value);
      }
      this.spinnerService.show();
      this.designService.updateDesign(designCopy).subscribe({
        next: (design: Design) => {
          this.spinnerService.hide();
          this.store.dispatch(loadTargetTreeSuccess({ payload: designCopy }));
          console.log('design: ', design);
          // this.design = design;
        },
        error: (error: any) => {
          this.spinnerService.hide();
          console.log('error: ', error);
        },
        complete: () => {
          console.log('Design updated');
          // TODO: Save the data & close the dialog
          this.dialogRef.close(this.design);
        },
      });
    } else {
      console.log('Form is invalid');
    }

    console.log('Design form: ', this.propertiesForm?.value);
    console.log('Section Form: ', this.sectionForm?.value);
  }

  onDocumentTypeChange(arg0: any) {
    console.log('selected doc type: ', arg0);
    const selectedDocumenType = this.printCodeTypes.find(docType => docType.print_spec_code === arg0);
    console.log('selected doc: ', selectedDocumenType);
    // if (selectedDocumenType) {
    //   console.log('selected doc X: ', selectedDocumenType.finished_size.split(' X '));
    //   const finishedSize = selectedDocumenType.finished_size.toLocaleLowerCase().split(' x ');
    //   const width = +finishedSize[0];
    //   const height = +finishedSize[1];
    //   this.propertiesForm.patchValue({
    //     documentWidth: width,
    //     documentHeight: height,
    //     documentOrientation: width > height ? 'landscape' : 'portrait',
    //   });
    // }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
