import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Auth_constants, endpoints, TemplateDesign } from '../constants/auth.constants';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { map, switchMap, tap } from 'rxjs';
import { TemplateConfig } from '@app/features/template-builder-tool/models/template-config';
import { AuthService } from '@app/core/services/auth.service';
import { TbtStorageService } from './tbt-storage.service';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  private readonly authService = inject(AuthService);
  private readonly http = inject(HttpClient);
  private readonly tbtStorageService = inject(TbtStorageService);

  private createHeaders(): HttpHeaders {
    const accessToken = this.tbtStorageService.getItem('access_token', true);
    const instanceUrl = this.tbtStorageService.getItem('instance_url', true);

    if (!accessToken || !instanceUrl) {
      throw new Error('Access token or instance URL not found in local storage.');
    }

    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });
  }
  createTemplate(userId: string, jobCode: string, templateName: string): Observable<any> {
    const instanceUrl = this.tbtStorageService.getItem('instance_url', true);
    const url = `${instanceUrl}${environment.templatesEndPoint}`;
    const headers = this.createHeaders();

    const body = {
      method_code: '1',
      user_id: userId,
      job_code: jobCode,
      template_name: templateName
    };

    return this.http.post<any>(url, body, { headers }).pipe(
      tap(response => {
        this.tbtStorageService.setItem('templateData$', JSON.stringify(response));
      })
    );
  }

  getStoredTemplateDetails(): any {
    const localData = this.tbtStorageService.getItem('templateData$');
    let obj;
    if (localData != undefined) {
      const data = JSON.parse(localData);
      obj = {
        templateId: data.template_id,
        templateName: data.template_name,
        templateStatus: data.template_status,
        userId: data.user_id,
        jobCode: data.job_code,
        template_approver: this.authService.getApproverName()
      }
    }
    return obj;
  }



  getlistTemplates(userId: string, isAuthor = true): Observable<TemplateDesign[]> {
    const instanceUrl = this.tbtStorageService.getItem('instance_url', true);
    const url = `${instanceUrl}${environment.templatesEndPoint}`;
    const headers = this.createHeaders();

    const body = {
      method_code: '4',
      user_id: userId,
      role: isAuthor ? 'Author' : 'Approver'
    };

    return this.http.post<TemplateDesign[]>(url, body, { headers });
  }



  getTemplateById(templateId: string, userId?: string): Observable<any> {
    const instanceUrl = this.tbtStorageService.getItem('instance_url', true);
    const url = `${instanceUrl}${environment.templatesEndPoint}`;
    const headers = this.createHeaders();
    const body = {
      method_code: '3',
      template_id: templateId,
      user_id: userId || ''
    };
    return this.http.post<any>(url, body, { headers }).pipe(tap((data) => {
      this.tbtStorageService.setItem("templateData$", JSON.stringify(data));
      return data;
    }));
  }

  getlistLanguageMappers(): Observable<any>{
    const instanceUrl = this.tbtStorageService.getItem('instance_url', true);
    const url = `${instanceUrl}${environment.templatesEndPoint}`;
    const headers = this.createHeaders();
    const body = {
      method_code: '8',
    };
    return this.http.post<any>(url, body, { headers });

  }

  getLanguageMappers(templateIds: string, templateName: string): Observable<any> {
    const instanceUrl = this.tbtStorageService.getItem('instance_url', true);
    const url = `${instanceUrl}${environment.templatesEndPoint}`;
    const headers = this.createHeaders();
    const body = {
      method_code: '7',
      template_ids: templateIds,
      template_name: templateName
    };
    return this.http.post<any>(url, body, { headers });
  }
  
  languageSelected(masterTemplateId: string,selectedLanguage: string,userId:string){
    const instanceUrl = this.tbtStorageService.getItem('instance_url', true);
    const url = `${instanceUrl}${environment.templatesEndPoint}`;
    const headers = this.createHeaders();
    const body = {
      method_code: '6',
      master_template_id: masterTemplateId,
      language_selected: selectedLanguage,
      user_id:userId
    };
    return this.http.post<any>(url, body, { headers });
  }

  updateTemplate(templateConfig: TemplateConfig): Observable<any> {
    const instanceUrl = this.tbtStorageService.getItem('instance_url', true);
    const url = `${instanceUrl}${environment.templatesEndPoint}`;
    const headers = this.createHeaders();

    return this.http.post<any>(url, templateConfig, { headers });
  }

  getDesigns(jobCode?: string): Observable<any> {    
    const url = this.authService.getAWSUrl() + endpoints.listPresignedUrl;
    const body = {
      "keys": [jobCode]
    };

    return this.http.post(url, body);
  }

  createTemplatePDF(
    pdfBase64: string,
    designName: string,
    templateId: string,
    status: string,
    jobcode: string,
    masterTemplateId: string,
    resourceType: "Customizable" | "Personalized",
    expiry_date: string
  ): Observable<any> {
    const instanceUrl = this.tbtStorageService.getItem('instance_url', true);
    const headers = this.createHeaders();

    const createData = {
      Title: `${designName} (${jobcode})`,
      PathOnClient: `${designName}.pdf`,
      VersionData: pdfBase64,
      MSD_CORE_HEQ_Associated_Template__c: templateId,
      MSD_CORE_Status__c: status,
      MSD_CORE_Resource_Code__c: jobcode,
      MSD_CORE_HEQ_Parent_Template_Id__c: masterTemplateId,
      MSD_CORE_HEQ_Content_Type__c: resourceType,
      MSD_CORE_Expiration_Date__c: expiry_date
    };

    const createUrl = `${instanceUrl}/services/data/v61.0/sobjects/ContentVersion`;
    return this.http.post(createUrl, createData, { headers });
  }

  contentVersion(
    pdfBase64: string,
    status: 'DRAFT' | 'COMPLETED',
    parent_template_id: string,
    templateData: any,
    printFileVersionId?: string,
    isPrintable?: boolean
  ): Observable<any> {
    const { design_name, template_id, job_code, customization_id, cloned_user_id, expiry_date} = templateData;

    return this.checkContentVersionExists(template_id, cloned_user_id).pipe(
      switchMap((contentDocumentId) => {
        const resourceType: "Customizable" | "Personalized" = status === 'DRAFT' ? 'Customizable' : 'Personalized';
        const instanceUrl = this.tbtStorageService.getItem('instance_url', true);
        const headers = this.createHeaders();

        const contentVersionUrl = `${instanceUrl}/services/data/v61.0/sobjects/ContentVersion`;
        const contentVersionPayload: any = {
          Title: `${design_name} (${job_code})`,
          PathOnClient: `${design_name}.pdf`,
          VersionData: pdfBase64,
          MSD_CORE_HEQ_Associated_Template__c: template_id,
          MSD_CORE_Status__c: status,
          MSD_CORE_Resource_Code__c: job_code,
          MSD_CORE_HEQ_Parent_Template_Id__c: parent_template_id,
          MSD_CORE_HEQ_Content_Type__c: resourceType,
          MSD_CORE_HEQ_Customization_Id__c: customization_id,
          MSD_CORE_Expiration_Date__c: expiry_date
        };
        if (contentDocumentId) {
          contentVersionPayload.ContentDocumentId = contentDocumentId;
          contentVersionPayload.IsMajorVersion = true;
        }
        // if (printFileVersionId) {
        //   contentVersionPayload.MSD_CORE_Print_Ready_File_Id__c = printFileVersionId;
        // }
        return this.http.post(contentVersionUrl, contentVersionPayload, { headers });
      })
    );
  }

  
  contentVersionUpdateForApproval(
    pdfBase64: string,
    designName: string,
    templateId: string,
    status: string,
    jobcode: string,
    masterTemplateId: string,
    resourceType: "Customizable" | "Personalized",
  ): Observable<any> {
    const instanceUrl = this.tbtStorageService.getItem('instance_url', true);
    const headers = this.createHeaders();

    const createUrl = `${instanceUrl}/services/data/v61.0/sobjects/ContentVersion`;
    const createData = {
      Title: `${designName} (${jobcode})`,
      PathOnClient: `${designName}.pdf`,
      VersionData: pdfBase64,
      MSD_CORE_HEQ_Associated_Template__c: templateId,
      MSD_CORE_Status__c: status,
      MSD_CORE_Resource_Code__c: jobcode,
      MSD_CORE_HEQ_Parent_Template_Id__c: masterTemplateId,
      MSD_CORE_HEQ_Content_Type__c: resourceType,
      // MSD_CORE_Fulfillment_Method__c: fulfillmentMethod
    };
    return this.http.post(createUrl, createData, { headers });

  }
  
  checkContentVersionExists(associated_template_id: string, clonedUserId: string): Observable<string | null> {
    const query = `SELECT ContentDocumentId FROM ContentVersion WHERE MSD_CORE_HEQ_Associated_Template__c = '${associated_template_id}' AND MSD_CORE_HEQ_Associated_Template__r.MSD_CORE_HEQ_Cloned_User__c = '${clonedUserId}'`;
    console.log('CV Query: ', query);
    
    const instanceUrl = this.tbtStorageService.getItem('instance_url', true);
    const url = `${instanceUrl}/services/data/v61.0/query?q=${encodeURIComponent(query)}`;
    const headers = this.createHeaders();

    return this.http.get<{ records: any[] }>(url, { headers }).pipe(
      map(response => response.records.length > 0 ? response.records[0].ContentDocumentId : null)
    );
  }

  getDesignFolderStructure(jobCode: string): Observable<any> {
    const url = `${this.authService.getAWSUrl()}${endpoints.listFiles}?folderPath=${jobCode}`;
    return this.http.get(url);
  }

}
